<template>
  <div>
    <slot></slot>

    <div v-if="!commentIsLoaded" class="card-body">
      <SubmitButton
        @click="fetchComment"
        :text="'Load comments'"
        :textBusy="'Loading...'"
        :isLoading="isLoading"
        :disabled="isLoading"
        class="btn btn-primary"
      ></SubmitButton>
    </div>

    <div v-else class="flex-grow-1 card-body">
      <div v-if="commentItems.length" class="list-group list-group-flush">
        <div
          v-for="comment in commentItems"
          :key="`key-${comment.commentId}`"
          class="list-group-item"
        >
          <TextView v-if="comment.comment" :text="comment.comment"></TextView>
          <div class="small text-muted">
            {{ comment.firstName }} {{ comment.lastName }}
            &mdash; {{ comment.createdAt | utcDateRelative }}
          </div>
        </div>
      </div>
      <div v-else>No comments</div>
    </div>

    <form @submit.prevent="send" class="card-footer p-3">
      <div class="mb-3">
        <textarea
          v-model.trim="comment"
          id="comment"
          class="form-control"
          placeholder="Enter message..."
        ></textarea>
      </div>
      <SubmitButton
        :text="'Add new comment'"
        :textBusy="'Sending...'"
        :isLoading="isSending"
        :disabled="isSending || comment === ''"
        class="btn btn-primary"
      ></SubmitButton>
    </form>
  </div>
</template>

<script>
import { mapState } from 'vuex';

export default {
  name: 'Comment',
  components: {
    TextView: () => import('@/components/TextView'),
    SubmitButton: () => import('@/components/button/SubmitButton'),
  },
  props: {
    session: {
      type: Object,
      required: true,
    },
  },
  computed: {
    ...mapState('comment', ['commentItems', 'commentIsLoaded']),
  },
  methods: {
    async fetchComment() {
      this.isLoading = true;
      try {
        const { sessionId } = this.session;
        await this.$store.dispatch('comment/fetchComment', {
          sessionId,
          lastCommentId: null,
        });
      } catch (err) {
        this.$store.dispatch('addSystemError', err, { root: true });
      } finally {
        this.isLoading = false;
      }
    },
    async send() {
      this.isSending = true;
      try {
        const { sessionId } = this.session;
        const { comment } = this;
        await this.$store.dispatch('comment/addComment', { sessionId, comment });
        this.comment = '';
      } catch (err) {
        this.$store.dispatch('addSystemError', err, { root: true });
      } finally {
        this.isSending = false;
      }
    },
  },
  data() {
    return {
      isLoading: false,
      isSending: false,
      comment: '',
    };
  },
  destroyed() {
    this.$store.dispatch('comment/resetComment');
  },
};
</script>
